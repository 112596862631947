// @ts-nocheck
import { DFI } from '../tienda'
import { getDataLeadRingpool } from '../utils/functionsTracking/functionsRingpool'

import {
  addConversionField,
  addDeclarativeField,
} from '../utils/funtionsRefactor/addDeclarativeField'

import {
  getUtagAnalitica,
  loadScript,
  getLeadId,
} from '../utils/utils'

const loadConversionLead = async (idLeadParam = '') => {
  const idLeadGet = globalThis.Piwik.getAsyncTracker().getVisitorId()
  const idLead = idLeadParam !== '' ? idLeadParam : idLeadGet
  const dataLeadLabels = await JSON.stringify(getDataLeadRingpool())

  const promiseConversion = globalThis.DAL.Promise()

  const endPointSendLabel =
    DFI.global.sendLabelRingpool +
    'idTag=' +
    DFI.global.idTagDelioClient +
    '&idvisitor=' +
    idLead +
    '&labels=' +
    dataLeadLabels +
    '&idServ=' +
    DFI.getRingPoolService()

  globalThis.DAL.Ajax({
    type: 'jsonp',
    url: endPointSendLabel,
  })
    .done(() => {
      promiseConversion.resolve()
    })
    .fail(() => {
      promiseConversion.reject()
    })
}

const loadConversion = async (idleadParams = '', fn) => {
  const idLeadGet = await getLeadId()
  const idlead = idleadParams != "undefined" ? idLeadParams : idLeadGet
  const nameClient = DFI.getClient()
  const tagConversion = DFI.global.tagConversion[nameClient]
  console.log({ idlead })
  const loadScriptPromise = loadScript(tagConversion + idlead)

  if (fn) {
    loadScriptPromise.then(fn)
  }

  return loadScriptPromise
}

const getInboundPhoneRP = () => {
  /** Teléfono inbound por defecto **/
  const defaultPhone = '900920052'

  const locationMapInbound = {
    '/c/tienda-online/particulares/': '900920155',
    '/c/particulares/es/productos-y-servicios/movil/catalogo-clientes/':
      '900920155',
    'oferta.vodafone.es': '900920230',
  }

  const pathname = location.pathname
  const hostname = location.hostname

  const phone =
    locationMapInbound[pathname] || locationMapInbound[hostname] || defaultPhone

  // Devolvemos el teléfono determinado.
  return phone
}

const reqPhone = (resp) => {
  globalThis.DRP.paintCapa(document.querySelectorAll('.drp-phone'))
  console.log({ resp })
}

const called = async (resp) => {
  //  Carga de conversion
  loadConversion(
    {
      key: 'uid',
      id: resp.uid,
    }

    //gracias llama a window.gracias(mirar con ojo esa funcion) //
  )

  // Se emite la notificacion
  DFI.notifyD3P({
    type: 'ringpool',
    result: 'OK',
  })

  const labelsRingpool=await getDataLeadRingpool()
  /**Actualiza etiquetas DEC y Conversion**/
  globalThis.DRP.loadLabels(labelsRingpool)

  /**Load Conversion : Declaratives and conversion**/
  loadConversionLead()
  /**EntryURL HACERLO CON JSON P LIBRERIA FECTH
   * Estos valores no estan en el utag data
   *entryurl: utag_data.entry_url,
    personalizacion: utag_data.personalizacion_ws,
   * **/
  globalThis.DAL.Ajax({
    type: 'jsonp',
    url: 'https://ws.walmeric.com/provision/wsclient/delioClientUid_put.html',
    data: {
      idTag: DFI.global.idTagDelioClient,
      uid: resp.uid,
      data: JSON.stringify({
        EDeclaratives: {
          entryurl: '',
          personalizacion: '',
        },
      }),
    },
  })
    .done(function (value) {
      //callback para la carga
      console.log('Envio correcto Delio')
    })
    .fail(function () {
      console.error('Error en la llamada ')
    })
}

export const initDrpTienda = async () => {
  /**
   * Objeto de configuracion del ringpool
   * reqPhone:  Callback que se ejecuta cuando se pide un número ringPool.
   * called : Callback que se ejecuta cuando una llamada es efectuada a un número RingPool.
   * **/

  const objTiendaRp = {
    capa: '.drp-phone, .f6-phone',
    labels: await getDataLeadRingpool(),
    campaignid: DFI.getRingPoolService(),
    maskPhone: '000 000 000',
    defaultPhone: getInboundPhoneRP(),
    reqPhone: reqPhone,
    called: called,
    idTag: DFI.global.idTagDelioClient,
  }

  globalThis.DRP.init(objTiendaRp)
}



/**
 *Funcion que contiene un Proxy , con todas las etiquetas declarativas y de conversion que se va a insertar en los formularios.
 * Se recorre todos los formularios que contengan la clase wg-form y introduce los input hidden para que luego la libreria DCL
 * pueda recoger dichos valores .
 */

export const addLabelsWalmeric = async() => {
  const consent = globalThis.consentValues || {} ;

  let Declarativas = {
    gb_asset_name                         :        globalThis.utag_data ? globalThis.utag_data.gb_asset_name : 'Error: no existe utag_data gb_asset_name',
    gb_channel                            :        globalThis.utag_data ? globalThis.utag_data.gb_channel : 'Error: no existe utag_data gb_channel',
    isp                                   :        globalThis.utag_data ? globalThis.utag_data.isp : 'Error: no existe utag_data isp',
    url                                   :        window.location.href,
    URL_sin_parametro                     :        DFI.selectUrl() ?? '',
    TipoProducto                          :        DFI.declarativeByUrlBinary() ? DFI.declarativeByUrlBinary().id:"defecto",
    TipoTienda                            :        DFI.declarativeByUrlBinary() ?  DFI.declarativeByUrlBinary().site:"defecto" ,
    adFormId                              :        globalThis.Adform._uid,
    idLead                                :        '',
    Page_name                             :        globalThis.utag_data ? globalThis.utag_data.page_name : 'Error: no existe utag_data page_name',
    utag_data_conf_fibra                  :        '',
    utag_data_conf_lineas                 :        '',
    utag_data_conf_tv                     :        '',
    utag_data_conf_lineas_adicionales     :        '',
    utag_data_conf_voz                    :        '',
    cookieAnalitica                       :        getUtagAnalitica(),
    location_cta                          :        globalThis.location_cta,
    _ttp                                  :        DFI.getCookie('_ttp') ?? "",
    ttclid                                :        DFI.getCookie('ttclid') ?? "",
    ad_storage                            :        consent ? consent.ad_storage : 'denied',
    analytics_storage                     :        consent ? consent.analytics_storage : 'denied',
    ad_personalization                    :        consent ? consent.ad_personalization : 'denied',
    ad_user_data                          :        consent ? consent.ad_user_data : 'denied'
  };
  
  let Conversion = {
    1: globalThis.womtp.c_vars[1],
    4: globalThis.womtp.c_vars[4],
    6: globalThis.womtp.c_vars[6],
    8: globalThis.womtp.c_vars[8],
    10: globalThis.womtp.c_vars[10],
  };
  
  const handleObj = {
    get: (target, prop) => {
      return target[prop];
    },
    set: (target, prop, value) => {
      try {
      const arrayPropiedades = Object.keys(target);
      if (!arrayPropiedades.includes(prop)) {
        throw new Error('Propiedad no existe en obj Datalead');
      }
      target[prop] = value;
      } catch (error) {
      console.error(error.message);
      }
  
      try {
      const newValue = value;
      if (!newValue) {
        throw new Error('Esta intentado insertar un valor vacio en la propiedad ' + prop);
      }
      target[prop] = value;
      } catch (error) {
      console.error(error);
      }
    },
  };
  
  const dataLeadCallMeDeclarativas = new Proxy(Declarativas, handleObj);
  const dataLeadCallMeConversion = new Proxy(Conversion, handleObj);
  
  const waitForElementWalmeric = (selector) => {
    return new Promise((resolve) => {
      const elements = document.querySelectorAll(selector)
  
      if (document.querySelectorAll(selector)) {
        return resolve(elements)
      }
      const observer = new MutationObserver(() => {
        if (element) {
          resolve(element)
          observer.disconnect()
        }
      })
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      })
    })
  }
  
  waitForElementWalmeric('.wg-form')
    .then((element) => {
      document.querySelectorAll('.wg-form').forEach((nodoElement) => {
        /*Refactorizalo en una funcion*/
        /*Declarativas*/
        Object.entries(Declarativas).forEach(([key, value]) => {
          let field = addDeclarativeField(key, value)
          nodoElement.appendChild(field)
        })

        /*Conversion*/
        Object.entries(Conversion).forEach(([key, value]) => {
          let field = addConversionField('c_var_' + key, value, 'wg-cvar')
          nodoElement.appendChild(field)
        })
      })
      globalThis.DCL.set.forms()
    })
    .catch((error) => {
      console.log(error)
    })
    return {
      Declarativas: dataLeadCallMeDeclarativas,
      Conversion: dataLeadCallMeConversion,
    }
}

/*Añade evento Submit */
const eventAddLabels = () => {
  const btnSubmitWalmeric = document.querySelectorAll('.wg-submit')
  btnSubmitWalmeric.forEach((elem) => {
    elem.addEventListener('click',() => {
      addLabelsWalmeric()
    })
  })
}

/**FUNCIONES MODAL
 * LUEGO SEPARAMOS EN SCRIPTS DIFERENTES Y EXPORTAMOS
 * **/

export const initModal = () => {
  eventAddLabels()
  globalThis.DCL_gracias = (resp) => {
    if (resp.result == 'ok') {
      /*Prueba loadConversion*/
      loadConversion(resp.leadId)

      console.log(resp)
    }
  }
}
